import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import withStyles from "@material-ui/core/styles/withStyles";

// Own components
import TicketListTable from "../../tables/Tickets/TicketListTable";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.jsx";
import Position from "components/Position";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Badge from "../../components/Badge/Badge";
import AddIcon from '@material-ui/icons/Add';

import {NavLink} from "react-router-dom";
import TablePagination from '../../pagination/table/TablePagination';
import useGlobal from '../../store';
import auth from '../../utils/auth';
import ReportFiltersModal from "../../modals/ReportsFiltersModal";
import AlertDelayModal from "../../modals/AlertDelayModal";
import SecurityAlertsModal from '../../modals/SecurityAlertsModal';
import { Typography } from '@material-ui/core';

const TicketListView = ({
  classes, 
  tickets, 
  paginationAction, 
  filters, 
  plan, 
  generateReport,
  archive,
  changeStatus
}) => {
  const { t, } = useTranslation('tickets');

  const ticketsCount = plan ? parseInt(plan?.features?.tickets) : 50;
  const currentTickets = tickets ? tickets?.length : 50;

  const disabledTickets = ticketsCount <= currentTickets;
  const reportCount = (ticketsCount - currentTickets) >= 0 ? ticketsCount - currentTickets : 0;

  const [session] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );
  const [modalStatus, setModalStatus] = useState({
    open: false,
    reportConfig: {}
  });

  const [modalDelayAlert, setModalDelayAlert] = useState({
    open: false,
    ticket: null,
    app: null
  });

  const [modalSecurity, setModalSecurity] = useState({
    open: false,
    ticket: null,
  });


  const hasPermission = (permission) => {
    if ( session.user && !auth.hasPermission(permission, session.permissions)) return false;
    return true;
  };

  return (
    <div className="Tickets">
      {/*TICKETS TABLE*/}
      <React.Fragment>
        <Card>
          <CardHeader color="info" text>
            <GridContainer>
              <GridItem xs={6}>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>{t("tickets.title")}</h4>
                </CardText>
              </GridItem>
              <GridItem xs={6}>
                <Position right>
                  <Badge color={"success"}>
                    {typeof tickets !== "undefined" && Array.isArray(tickets)
                      ? tickets.length
                      : 0}{" "}
                    {t("tickets.title")}
                  </Badge>
                  <NavLink
                    to={
                      !disabledTickets || !hasPermission("ticketsCreated")
                        ? "/tickets/new"
                        : "#"
                    }
                  >
                    <Button
                      justIcon
                      round
                      simple
                      color={"success"}
                      disabled={disabledTickets}
                    >
                      <AddIcon />
                    </Button>
                  </NavLink>
                </Position>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <div
                  className={`${classes.right} ${classes.cursor}`}
                  onClick={() => changeStatus()}
                >
                  <Badge color={"primary"}>
                    {filters.archived
                      ? t("tickets.viewNotArchived")
                      : t("tickets.viewArchived")}
                  </Badge>
                </div>
                {!filters.archived ? (
                  <div className={classes.right}>
                    <Badge color={"info"}>
                      {t("tickets.remaning")}: {reportCount}
                    </Badge>
                  </div>
                ) : (
                  []
                )}
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.right}>
                  <Typography variant="p" className={classes.paddingText}>
                    {t("tickets.dateRangeInfo")}
                  </Typography>
                  <Button
                    size="sm"
                    color="info"
                    onClick={() => {
                      setModalStatus({
                        open: true,
                        reportConfig: {},
                      });
                    }}
                  >
                    {t("tickets.generateReport")}
                  </Button>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <TablePagination
                  data={tickets ? tickets : []}
                  paginationAction={paginationAction}
                  filter={filters}
                />
                <TicketListTable
                  setModalDelayAlert={setModalDelayAlert}
                  setModalSecurity={(open) => {
                    setModalSecurity(open);
                  }}
                  data={tickets}
                  archive={archive}
                />
              </GridItem>
            </GridContainer>
            <ReportFiltersModal
              modalStatus={modalStatus}
              setModalStatus={setModalStatus}
              generateReport={generateReport}
            />

            <AlertDelayModal
              modalStatus={modalDelayAlert}
              setModalStatus={setModalDelayAlert}
              generateReport={generateReport}
            />

            <SecurityAlertsModal
              modalStatus={modalSecurity}
              setModalStatus={setModalSecurity}
            />
          </CardBody>
        </Card>
      </React.Fragment>
    </div>
  );
};

TicketListView.propTypes = {
  tickets: PropTypes.array.isRequired
};

const styles = theme => ({
  center: {
    textAlign: "center"
  },
  right: {
    float: "right",
  },
  cursor: {
    cursor: 'pointer'
  },
  paddingText: {
    padding: '10px'
  }
});

export default withStyles(styles)(TicketListView);
