import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useSubscription } from "react-apollo-hooks";
import { useMutation } from "@apollo/react-hooks";
// Own components
import LoadingCircleView from "../../Views/LoadingCircleView";
import TicketListView from "../../Views/Tickets/TicketListView";

import { TICKET_REPORTS_CHANGED_SUBSCRIPTION } from "./Subscriptions";
import { TICKETS_FEED } from "./Queries";

import useGlobal from "../../store";
import { ticketReportChangedNotification } from "../../utils/snackNotifications";
import { GET_PLAN_FEATURES_APP } from "../Locations/gql/Queries";
import auth from "../../utils/auth";
import history from "../../utils/history";
import moment from "moment";
import {
  GENERATE_TICKET_REPORT_XSL,
  UPDATE_TICKET_MUTATION,
} from "./Mutations";

function Tickets(props) {
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );
  const { t } = useTranslation(["tickets", "common"]);

  const [filters, setFilters] = useState({
    app: session.user ? session.appRef._id : "",
    skip: 0,
    first: 25,
    user: session.user ? session.user._id : "",
    session: auth.getUserSessionData(session),
    archived: false,
    created_gte: moment().startOf('day').subtract(30, 'days'),
    created_lte: moment().endOf('day'),
  });

  const {
    data: dataTickets,
    error: errorTickets,
    loading: loadingTickets,
    refetch: refetchTickets,
  } = useQuery(TICKETS_FEED, {
    variables: filters,
    fetchPolicy: "network-only",
    nextFetchPolicy: 'cache-first'
  });

  console.log({
    dataTickets
  })

  const { data, error, loading } = useSubscription(
    TICKET_REPORTS_CHANGED_SUBSCRIPTION,
    {
      variables: { where: { app: session.user ? session.appRef._id : "" } },
      onSubscriptionData: ({ client, subscriptionData }) => {
        const { operation, node } = subscriptionData.data.ticketReportChanged;
        ticketReportChangedNotification(t, globalActions, operation, node);
        refetchTickets();
      },
    }
  );

  const { data: dataPlan } = useQuery(GET_PLAN_FEATURES_APP, {
    variables: {
      where: {
        _id: session.user ? session.appRef._id : "",
      },
    },
    fetchPolicy: "network-only",
  });

  const [updateTicket] = useMutation(UPDATE_TICKET_MUTATION);

  const paginationAction = async (values) => {
    setFilters(values);
  };

  const [generateReportMutation] = useMutation(GENERATE_TICKET_REPORT_XSL, {
    onCompleted: (mutationData) => {
      if (mutationData) {
        globalActions.notification.openNotification("tr", {
          message: "Procesando Reporte",
          color: "success",
        });
      }
    },
  });

  useMemo(() => {
    if (
      session.user &&
      !auth.hasPermission("ticketsView", session.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: t("common:common.hasNotPermission"),
        color: "danger",
      });
      history.push("dashboard");
    }
  }, [session]);

  if (loadingTickets) {
    return <LoadingCircleView />;
  } else {
    return (
      <TicketListView
        plan={dataPlan?.getPlanFeaturesApp || null}
        tickets={dataTickets.tickets}
        paginationAction={paginationAction}
        filters={filters}
        generateReport={({ from, to }) => {
          const variables = {
            data: {
              app: session.appRef._id,
              session: {
                user: {
                  email: session.user.email,
                  firstName: session.user.firstName,
                  lastName: session.user.lastName,
                  _id: session.user._id,
                },
              },
              dateRange: {
                from: from, //moment(from).utcOffset(0, true).format(),
                to: to, //moment(to).utcOffset(0, true).format()
              },
              query: {
                timezone: moment.tz.guess(),
              },
            },
          };

          generateReportMutation({
            variables,
          });
        }}
        archive={(ticket) => {
          const validTicketSegmentAssignConfigs = ticket.ticketSegmentAssignConfigs
            .filter((assign) => {
              return assign.segment && assign.userMobile;
            })
            .map((assign) => {
              return {
                segmentId: assign.segment._id,
                userMobileId: assign.userMobile._id,
              };
            });

          const variables = {
            data: {
              app: ticket.app,
              name: ticket.name,
              description: ticket.description,
              emails: {
                set: ticket.emails,
              },
              triggers: {
                connect: ticket.triggers.map((trigger) => ({
                  _id: trigger._id,
                })),
              },
              finisher: {
                connect: {
                  _id: ticket.finisher._id,
                },
              },
              ticketSegmentAssignConfigs: validTicketSegmentAssignConfigs,
              whoResolves: ticket.whoResolves,
              segment: ticket.segment,
              archived: false,
              pdfReportConfig: ticket.pdfReportConfig,
              topOffender: ticket.topOffender,
              archived: !ticket.archived,
            },
            where: {
              _id: ticket._id,
            },
          };
          updateTicket({
            variables,
          });
          refetchTickets();
        }}
        changeStatus={() => {
          setFilters({
            ...filters,
            archived: !filters.archived
          });
        }}
      />
    );
  }
}

const styles = (theme) => ({
  center: {
    textAlign: "center",
  },
});

export default Tickets;
